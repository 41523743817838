import { Button, ButtonProps } from '@mui/material'
import React from 'react'

function CancelButton(props: ButtonProps) {
    return (
        <Button {...props} className={`${props.className} cancel-btn`} color="secondary">
            {props.children}
        </Button>
    )
}

export default CancelButton
