import { AccentButton } from '@/components/atoms/Button/AccentButton'
import InputField from '@/components/atoms/InputField'
import { useTypedForm } from '@/hooks/useTypedForm'
import { TAllFormValues } from '@/react-hook-form/types'
import React from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import CancelButton from '@/components/atoms/Button/CancelButton'
import { navigate } from 'gatsby'
import { routes } from '@/routes'
import PasswordField from '@/components/atoms/InputField/PasswordField'
import Bubble from '@/components/atoms/Bubble'

function ResetPasswordForm(props: { onSubmit: SubmitHandler<TAllFormValues['ResetPassword']> }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useTypedForm('ResetPassword')

    const handleCancel = () => navigate(routes.login)

    return (
        <form className="login-form m-auto-0 width-550" onSubmit={handleSubmit(props.onSubmit)}>
            <h4 className="fw-600 fz-20 text-center">パスワード再設定</h4>
            <p className="fz-16 fw-100 my-20">以下の条件を満たすパスワードを設定してください。</p>
            <Bubble className={'bubble-bg-lightGrey1'}>
                <ul className="ma-0">
                    <li>半角の英大文字、英小文字、数字をそれぞれ1文字以上含む</li>
                    <li>12文字以上</li>
                    <li>メールアドレスと異なる</li>
                </ul>
            </Bubble>
            <div className="form-content display-flex flex-column mt-30">
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { ref, ...rest } }) => {
                        return (
                            <PasswordField
                                {...rest}
                                size="medium"
                                id="password"
                                placeholder="新しいパスワード"
                                helperText={errors.password?.message}
                                error={!!errors.password?.message ?? false}
                            />
                        )
                    }}
                />
                <Controller
                    name="code"
                    control={control}
                    render={({ field: { ref, ...rest } }) => {
                        return (
                            <InputField
                                {...rest}
                                size="medium"
                                id="code"
                                placeholder="認証コード"
                                error={!!errors.code?.message ?? false}
                                helperText={errors.code?.message}
                            />
                        )
                    }}
                />
            </div>
            <div className="text-center mt-30">
                <CancelButton className="fz-14 fw-600" onClick={handleCancel}>
                    キャンセル
                </CancelButton>
                <AccentButton className="fz-14 fw-600 ml-20" type="submit">
                    メール送信
                </AccentButton>
            </div>
        </form>
    )
}

export default ResetPasswordForm
