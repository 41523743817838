import { AccentButton } from '@/components/atoms/Button/AccentButton'
import InputField from '@/components/atoms/InputField'
import { useTypedForm } from '@/hooks/useTypedForm'
import { TAllFormValues } from '@/react-hook-form/types'
import React from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import CancelButton from '@/components/atoms/Button/CancelButton'
import { navigate } from 'gatsby'
import { routes } from '@/routes'

function ForgotPasswordForm(props: { onSubmit: SubmitHandler<TAllFormValues['ForgotPassword']> }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useTypedForm('ForgotPassword')

    const handleCancel = () => navigate(routes.login)

    return (
        <form className="login-form m-auto-0 width-450" onSubmit={handleSubmit(props.onSubmit)}>
            <h4 className="fw-600 fz-20 text-center">パスワード再設定</h4>
            <p className="fz-16 fw-100 mt-20 mb-40">
                パスワードを再設定するメールアドレスを入力してください。 パスワード再設定用の認証コードが送信されます。
            </p>
            <Controller
                name="email"
                control={control}
                render={({ field: { ref, ...rest } }) => {
                    return (
                        <InputField
                            {...rest}
                            size="medium"
                            id="email"
                            placeholder="メールアドレス"
                            error={!!errors.email?.message ?? false}
                            helperText={errors.email?.message}
                        />
                    )
                }}
            />
            <div className="text-center mt-30">
                <CancelButton className="fz-14 fw-600" onClick={handleCancel}>
                    キャンセル
                </CancelButton>
                <AccentButton className="fz-14 fw-600 ml-20" type="submit">
                    メール送信
                </AccentButton>
            </div>
        </form>
    )
}

export default ForgotPasswordForm
